export const createSeriesOption = (data, settings) => {
  const {
    xColumn, yColumn, valueColumn, labelColumn,
  } = settings;
  const x = [...new Set(data.map((item) => item[labelColumn]))];
  const datasetWithFilters = [];
  const seriesList = [];
  x.forEach((item) => {
    // console.log('item', item);
    const datasetId = `dataset_ + ${item}`;
    datasetWithFilters.push({
      id: datasetId,
      fromDatasetId: 'dataset_raw',
      transform: {
        type: 'filter',
        config: {
          and: [
            // { dimension: 'Year', gte: 1950 },
            { dimension: labelColumn, '=': item },
          ],
        },
      },
    });
    seriesList.push({
      type: 'line',
      datasetId,
      showSymbol: false,
      name: item,
      endLabel: {
        show: true,
        formatter(params) {
          return `${params.data[labelColumn]} : ${params.data[valueColumn]}`;
        },
      },
      labelLayout: {
        moveOverlap: 'shiftY',
      },
      emphasis: {
        focus: 'series',
      },
      encode: {
        x: xColumn,
        y: yColumn,
        label: [labelColumn, valueColumn],
        itemName: xColumn,
        tooltip: [valueColumn],
      },
    });
  });

  const option = {
    animationDuration: 50000,
    dataset: [
      {
        id: 'dataset_raw',
        source: data,
      },
      ...datasetWithFilters,
    ],
    title: {
      text: '',
    },
    tooltip: {
      order: 'valueDesc',
      trigger: 'axis',
    },
    xAxis: {
      type: 'time',
      nameLocation: 'middle',
    },
    yAxis: {
      name: '',
    },
    grid: {
      right: 140,
    },
    series: seriesList,
  };
  return option;
};
