<template>
  <div
    ref="echart"
    v-resize="onResize"
    style="width: inherit; height: inherit"
  />
</template>
<script>
import * as echarts from 'echarts/dist/echarts.js';
import ApiService from '../../../services/api.service';

import { createSeriesOption } from './lib/transformer';

import { createSeriesQuery } from '../../../lib/query-creator';

export default {
  components: {},
  props: ['dataset'],
  data() {
    return {
      chart: null,
    };
  },
  computed: {},
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.$store.commit('mSetLoading', true);
      if (this.dataset && this.dataset.table_name) {
        const appConfig = this.$store.state.appConfig;

        const params = {
          xColumn: 'Date',
          yColumn: 'value',
          valueColumn: 'value',
          labelColumn: 'Location',
        };
        console.log('this.dataset', this.dataset);
        const q = createSeriesQuery(this.dataset);
        const response = await ApiService.post('/raw_sql/', { q });
        const data = response.data;
        const option = createSeriesOption(data, params);
        console.log('option', option);
        this.draw(option);
      }
      this.$store.commit('mSetLoading', false);
    },
    draw(option) {
      this.chart = echarts.init(this.$refs.echart);
      this.chart.setOption(option);
    },
    rendered() {},
    onResize() {
      if (this.chart) {
        this.chart.resize();
      }
    },
  },
};
</script>
